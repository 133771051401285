import React, { useState } from "react";
import Table from "./Table";
import Chart from "./Chart";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Main(props) {
  const [record, setRecord] = useState({ oxygen: "", note: "" });
  const [startDate, setStartDate] = useState(new Date());

  const records = props.records;
  const setRecords = props.setRecords;
  const saveRecordsToLocalStorage = props.saveRecordsToLocalStorage;
  const deleteRecord = props.deleteRecord;

  const updateForm = (event) => {
    const { name, value } = event.target;
    if ((name === "oxygen" && value <= 100 && value >= 0) || name === "note") {
      setRecord((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const saveRecord = (event) => {
    if (record.oxygen !== "") {
      const toSaveRecord = { ...record, DateAndTime: Date.now() };

      setRecords((prev) => {
        if (prev.length === 0) {
          return [{ ...toSaveRecord }];
        } else {
          return [...prev, { ...toSaveRecord }];
        }
      });

      saveRecordsToLocalStorage();
    }

    event.preventDefault();
  };

  return (
    <main className="bg-light">
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-12">
          <h3>My Chart</h3>
          <Chart records={records} />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <h3>Add a record</h3>
          <form>
            <label>Oxygen Saturation (SpO2)</label>
            <input
              name="oxygen"
              className="form-control"
              placeholder="Enter your oxygen level"
              value={record.oxygen}
              onChange={updateForm}
            />
            <label>Note</label>
            <input
              name="note"
              className="form-control"
              placeholder="Add a note..."
              value={record.note}
              onChange={updateForm}
            />
            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <button className="btn btn-primary" onClick={saveRecord}>
              Save
            </button>
          </form>
        </div>
      </div>
      <hr></hr>
      <Table
        records={records}
        setRecords={setRecords}
        saveRecordsToLocalStorage={saveRecordsToLocalStorage}
        deleteRecord={deleteRecord}
      />
    </main>
  );
}

export default Main;
