import React, { useEffect, useRef } from "react";
import Row from "./Row";

function Table(props) {
  const records = props.records;
  const setRecords = props.setRecords;

  let setLocalRecords = useRef(() => {});

  useEffect(() => {
    setLocalRecords.current();
  }, []);

  setLocalRecords.current = () => {
    if (localStorage.getItem("records")) {
      let localRecords = JSON.parse(localStorage.getItem("records"));
      setRecords([...localRecords]);
    }
  };

  const deleteRecord = props.deleteRecord;

  return (
    <div>
      <h3 id="my-records">My Records</h3>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width="25%">Date</th>
              <th width="25%">Time</th>
              <th width="25%">Oxygen Saturation (SpO2)</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => {
              return (
                <Row
                  key={record.DateAndTime}
                  record={record}
                  onClick={deleteRecord}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
