import React from "react";

function Row(props) {
  const record = props.record;

  let fullDate, date, time, oxygen, note, colourClass;

  if (record) {
    fullDate = new Date(record.DateAndTime);
    // console.log(fullDate);
    date =
      fullDate.toDateString().split(" ")[1] +
      " " +
      fullDate.toDateString().split(" ")[2] +
      " " +
      fullDate.toDateString().split(" ")[3];

    time = fullDate.toLocaleTimeString("en-US");

    oxygen = record.oxygen;
    note = record.note;

    if (oxygen > 93) {
      colourClass = "table-success";
    } else if (oxygen >= 90) {
      colourClass = "table-warning";
    } else {
      colourClass = "table-danger";
    }
  }

  return (
    <tr>
      <td>{date}</td>
      <td>{time}</td>
      <td className={colourClass}>{record ? oxygen : " "}</td>
      <td>{record ? note : " "} </td>
    </tr>
  );
}

export default Row;
