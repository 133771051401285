/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

function Heading() {
  return (
    <div id="heading">
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#heading">
            COVID SpO2 Log
          </a>
        </div>
      </nav>
    </div>
  );
}

export default Heading;
