import React, { useState } from "react";
import Heading from "./Heading.jsx";
import Main from "./Main";
import Footer from "./Footer";

function App() {
  const [records, setRecords] = useState([]);

  const saveRecordsToLocalStorage = () => {
    localStorage.setItem("records", JSON.stringify(records));
  };

  const deleteRecord = (event) => {
    const id = event.target.id;
    console.log(id);

    const remainingRecords = records.filter((record) => {
      return record.DateAndTime !== id;
    });

    setRecords((prev) => [...remainingRecords]);
    saveRecordsToLocalStorage();
  };

  return (
    <div className="bg-light" height="100vh">
      <Heading />
      <Main
        records={records}
        setRecords={setRecords}
        saveRecordsToLocalStorage={saveRecordsToLocalStorage}
        deleteRecord={deleteRecord}
      />
      <Footer />
    </div>
  );
}

export default App;
